// for now only use for MABA Layouts, due to multiple application using MABA layout.
const generateXfPath = ({ siteName, xfComponentId }) => {
	if (siteName === 'MABA') {
		return `/content/experience-fragments/maba/$lang/site/${xfComponentId}/master`;
	}

	if (siteName === 'MAS-KARGO-LOGISTICS') {
		return `/content/experience-fragments/mas-kargo-logistics/$lang/site/${xfComponentId}/master`;
	}
	
	return `/content/experience-fragments/mabes/$lang/site/${xfComponentId}/master`;
};


export {
	generateXfPath,
};
