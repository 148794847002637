<script>
export default {
	name: 'SubTabBonusSideTrip',
};
</script>

<script setup>
import dayjs from 'dayjs';
import Tooltip from '~/components/Tooltip.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import PassengerAndCabinClass from '~/components/BookingWidget/PassengerAndCabinClass.vue';
import { useBSTOverlayInterimData } from '~composables/booking-widget/useBSTOverlayInterimData.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { waitFor } from '~/logic/helpers/utils.js';
import { useIsCountryVariant, useSyncPassengerDetails } from '~/logic/composables/booking-widget/usePassengers.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';


const props = defineProps({
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },

	ondSelection: { type: Object, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },

	subHeaderTooltipCTATextMobileLabel: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	passengerSelectionQuestion: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
	enableONDLocaleTranslation: { type: Boolean, default: false },
	countryVariants: { type: Array, default: () => [] },
});
const emit = defineEmits([
	'save-search-data',
	'show-bst-overlay',
	'form-submission',
]);

const formWrapper = ref(null);
const passengerAndCabinClassEl = ref(null);
const dateRangePickerEl = ref(null);
const ondPickerEl = ref(null);

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const ondValueFrom = ref(null);
const ondValueTo = ref(null);


const handleSubmitValid = (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);
	
	const searchData = {
		isBSTTab: true,
		
		dateRangeValueFrom: dateRangeValueFrom.value,
		dateRangeValueTo: dateRangeValueTo.value,
		isOneWay: !dateRangeValueTo.value,
		
		ondValueFrom: ondValueFrom.value,
		ondValueTo: ondValueTo.value,
		
		cabinClassValue: passengerAndCabinClassEl.value.cabinClassValue,
		adultsCount: passengerAndCabinClassEl.value.adults.count,
		teensCount: passengerAndCabinClassEl.value.teens.count,
		childrenCount: passengerAndCabinClassEl.value.children.count,
		infantsCount: passengerAndCabinClassEl.value.infants.count,
	};
	
	emit('save-search-data', searchData);
	emit('show-bst-overlay');
	emit('form-submission', searchData);
	
	commitDataToBSTOverlay('bonus-side-trip', {
		ondValueFrom: searchData.ondValueFrom,
		ondValueTo: searchData.ondValueTo,
		dateRangeValueFrom: searchData.dateRangeValueFrom,
		dateRangeValueTo: searchData.dateRangeValueTo,
		isOneWay: searchData.isOneWay,
		
		adultsCount: searchData.adultsCount,
		teensCount: searchData.teensCount,
		childrenCount: searchData.childrenCount,
		infantsCount: searchData.infantsCount,
		
		cabinClassValue: searchData.cabinClassValue,
		payByEnrichPoints: false,
	});
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};


const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;

	const isOneWay = dateRangePickerEl.value?.isOneWay;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.dateDeparture ||
		(!isOneWay && !form.values.dateReturn) ||
		Object.keys(form.errors).length !== 0
	);
});

const restorePastSearch = (searchData) => {
	ondValueFrom.value = searchData.ondValueFrom;
	ondValueTo.value = searchData.ondValueTo;
	dateRangeValueFrom.value = searchData.dateRangeValueFrom;
	dateRangeValueTo.value = searchData.dateRangeValueTo;
	
	passengerAndCabinClassEl.value.setValues({
		adults: searchData.adultsCount,
		teens: searchData.teensCount,
		children: searchData.childrenCount,
		infants: searchData.infantsCount,
		cabinClass: searchData.cabinClassValue,
	});
	
	// set one-way
	dateRangePickerEl.value.handleSetIsOneWay(!!searchData.isOneWay);
};

const { commitDataToBSTOverlay, registerNotifier } = useBSTOverlayInterimData();

registerNotifier('bonus-side-trip', async (newData) => {
	ondValueFrom.value = newData.ondValueFrom;
	ondValueTo.value = newData.ondValueTo;
	
	dateRangeValueFrom.value = newData.dateRangeValueFrom;
	dateRangeValueTo.value = newData.dateRangeValueTo;
	
	// const wait = waitFor(() => !!passengerAndCabinClassEl.value, 400, 30); // <-- 400ms * 30 = 12 seconds
	// await wait.start();
	
	passengerAndCabinClassEl.value.setValues({
		adults: newData.adultsCount,
		teens: newData.teensCount,
		children: newData.childrenCount,
		infants: newData.infantsCount,
		cabinClass: newData.cabinClassValue,
	});
	
	dateRangePickerEl.value.handleSetIsOneWay(!!newData.isOneWay);
});

useSyncPassengerDetails(passengerAndCabinClassEl);

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
	subTabName: 'bonus-side-trip',
});

registerQueryChange(async (query) => {
	if (!query) return;

	ondValueFrom.value = query.locationFrom;
	ondValueTo.value = query.locationTo;

	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});

	dateRangeValueFrom.value = query.dateDeparture ?? '';
	dateRangeValueTo.value = query.dateReturn ?? '';

	await nextTick();
	
	passengerAndCabinClassEl.value?.setValues({
		...(query.adultsCount ? { adults: parseInt(query.adultsCount) } : null),
		...(query.teensCount ? { teens: parseInt(query.teensCount) } : null),
		...(query.childrenCount ? { children: parseInt(query.childrenCount) } : null),
		...(query.infantsCount ? { infants: parseInt(query.infantsCount) } : null),
		...(query.cabinClass ? { cabinClass: parseInt(query.cabinClass) } : null),
	});

	if (query.isOneWay === 'true') {
		dateRangePickerEl.value.handleSetIsOneWay(!!query.isOneWay);
	}
});

const isCountryVariant = ref(false);

watch([ondValueFrom, ondValueTo], (newValue) => {
	if (ondValueFrom.value && ondValueTo.value) {
		// both ond to and from present, check for invalid datepicker selection
		if (dayjs(dateRangeValueFrom.value).isBefore(computedDatePickerMinDate.value)) {
			dateRangeValueFrom.value = null;
		}
		if (dayjs(dateRangeValueTo.value).isAfter(computedDatePickerMaxDate.value)) {
			dateRangeValueTo.value = null;
		}
	}
	
	// reset teen passenger count, once user select non-uk airport
	isCountryVariant.value = useIsCountryVariant(props.countryVariants, { origin: ondValueFrom.value, destination: ondValueTo.value });
	if (isCountryVariant.value) return;
	passengerAndCabinClassEl.value.setValues({
		teens: 0,
	});
});

const computedDatePickerMinDate = computed(() => {
	let result = null;
	if (!ondValueTo.value) return result;
	
	const today = dayjs();
	const travelPeriodFrom = ondValueTo.value?.travelPeriodFrom;
	if (!travelPeriodFrom) return result;
	
	if (dayjs(travelPeriodFrom).isBefore(today)) return 't';
	
	return travelPeriodFrom;
});
const computedDatePickerMaxDate = computed(() => {
	let result = 't+360d';
	if (!ondValueTo.value) return result;
	
	const travelPeriodTo = ondValueTo.value?.travelPeriodTo;
	if (!travelPeriodTo) return result;
	
	return travelPeriodTo;
});

defineExpose({
	restorePastSearch,
});

const focusToDatePicker = (newValue) => {
	if (newValue) dateRangePickerEl.value?.inputElFrom?.focus();
};

const groupLabelsOverwrite = computed(() => {
	if (!props.ondSelection) return null;

	return {
		'NEAR_YOU': props.ondSelection?.nearYouLabel,
		'POPULAR_DEST': props.ondSelection?.popularDestinationsLabel,
		'WITH_MH': props.ondSelection?.withMalaysiaAirlinesLabel,
		'CODESHARE': props.ondSelection?.withCodeShareAndPartnerNetworkLabel,
	};
});
</script>

<template>
<div class="SubTabBonusSideTrip">
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>

		<div v-if="props.subHeaderTabDescription" v-html-sanitize="props.subHeaderTabDescription" class="mb-5"></div>

		<Tooltip v-if="props.subHeaderTabTooltipLabel" class="inline-flex mb-5">
			<template #default>
				<div class="flex items-center">
					<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
					{{ props.subHeaderTabTooltipLabel }}
				</div>
			</template>
			<template #mobile-title>
				{{ props.subHeaderTabTooltipLabel }}
			</template>
			<template #tooltip-content>
				{{ props.subHeaderTabTooltipBody }}
			</template>
			<template #mobile-cta-label>
				{{ props.subHeaderTooltipCTATextMobileLabel }}
			</template>
		</Tooltip>

		<div class="flex gap-6 lg:flex-col">
			<div class="w-6/10 lg:w-full">
				<ONDPicker
					ref="ondPickerEl"
					v-model:modelValueFrom="ondValueFrom"
					v-model:modelValueTo="ondValueTo"
					nameFrom="locationFrom"
					nameTo="locationTo"
					:requiredFrom="true"
					:requiredTo="true"
					:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
					:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
					:labelTextFrom="props.ondSelection?.fromLabel"
					:labelTextTo="props.ondSelection?.toLabel"
					:noResultsText="props.ondSelection?.noResultMessage"
					:enableONDLocaleTranslation="props.enableONDLocaleTranslation"
					ondListIdentifier="FLIGHT_SEARCH_BST"
					:groupLabelsOverwrite="groupLabelsOverwrite"
					@update:modelValueTo="focusToDatePicker"
				>
					<template #from-picker-mobile-title>
						<span>{{ props.fromQuestionMobile }}</span>
					</template>
					<template #to-picker-mobile-title>
						<span>{{ props.toQuestionMobile }}</span>
					</template>
				</ONDPicker>
			</div>
			<div class="w-4/10 lg:w-full">
				<DateRangePicker
					ref="dateRangePickerEl"
					v-model:modelValueFrom="dateRangeValueFrom"
					v-model:modelValueTo="dateRangeValueTo"
					:textFieldAttrsFrom="{
						ariaLabel: props.datePicker?.departureDateLabel || i18nGlobal.t('Depart'),
						placeholder: i18nGlobal.t('Select a date'),
					}"
					:textFieldAttrsTo="{
						ariaLabel: props.datePicker?.returnDateLabel || i18nGlobal.t('Return'),
						placeholder: i18nGlobal.t('Select a date'),
					}"
					:minDate="computedDatePickerMinDate"
					:maxDate="computedDatePickerMaxDate"
					:disablePastDates="true"
					nameFrom="dateDeparture"
					nameTo="dateReturn"
					:requiredFrom="true"
					:requiredTo="true"
					:requiredErrorMsgFrom="props.datePicker?.invalidDateErrorMessage"
					:requiredErrorMsgTo="props.datePicker?.invalidDateErrorMessage"
					:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
					:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
					:labelOneWay="props.datePicker?.oneWayCTAText ?? $t('One-way')"
				>
					<template #mobile-title>
						{{ props.datePickerQuestionMobile }}
					</template>
				</DateRangePicker>
			</div>
		</div>
		
		<div class="flex mt-6 gap-6 md:flex-col">
			<div class="flex flex-grow gap-6 md:flex-col">
				<div class="w-6/10 lg:w-full">
					<PassengerAndCabinClass
						ref="passengerAndCabinClassEl"
						:labelText="props.passengerSelection?.passengerAndCabinClassLabel"
						:passengerLabel="props.passengerSelection?.passengerLabel"
						:adultLabel="props.passengerSelection?.adultLabel"
						:teenagerLabel="props.passengerSelection?.teenagerLabel"
						:childrenLabel="props.passengerSelection?.childrenLabel"
						:infantLabel="props.passengerSelection?.infantLabel"
						:cabinClassLabel="props.passengerSelection?.cabinClassLabel"
						:economyLabel="props.passengerSelection?.economyLabel"
						:businessLabel="props.passengerSelection?.businessLabel"
						:businessSuiteLabel="props.passengerSelection?.businessSuiteLabel"
						:isShowEconomyClass="!props.passengerSelection?.isHideEconomy"
						:isShowBusinessClass="!props.passengerSelection?.isHideBusiness"
						:isShowBusinessSuiteClass="!props.passengerSelection?.isHideBusinessSuite"
						:isCountryVariant="useIsCountryVariant(props.countryVariants, { origin: ondValueFrom, destination: ondValueTo })"
					>
						<template #after-passengers>
							<AppHyperlink
								class="font-semibold"
								:href="props.passengerSelection?.groupBookingPageURL"
								:showExternalLinkIcon="true"
								target="_blank"
							>
								{{ props.passengerSelection?.moreThan9PassengerDescription || $t('More than 9 passengers?') }}
							</AppHyperlink>
						</template>

						<template #mobile-title>
							{{ props.passengerSelectionQuestion }}
						</template>
					</PassengerAndCabinClass>
				</div>
			</div>
			<div class="ml-auto md:ml-0">
				<CTAButton
					v-aa="[
						{
							clickName: props.searchFlightCTAText,
							clickComponentType: 'Button',
							componentName: 'SubTabBonusSideTrip',
							componentID: props.componentIdAA,
						},
						{
							name: props.searchFlightCTAText,
							type: 'exit',
						},
					]"
					:sizeType="isMobileViewport ? 'small' : 'large'"
					:isSubmit="true"
					:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
					:disabled="isBtnSubmitDisabled"
				>
					{{ props.searchFlightCTAText }}
				</CTAButton>
			</div>
		</div>

		<div
			v-if="props.note"
			class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
		>
			<icon-fas-circle-info class="fill-primary-blue-base text-base" />
			<div class="text-sm">
				<p>{{ props.note }}</p>
			</div>
		</div>
	</FormWrapper>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabBonusSideTrip {
	
}

</style>
