<script>
export default {
	name: 'DatePicker',
	inheritAttrs: false,
};
</script>

<script setup>
import dayjs from 'dayjs';
import MHCalendarSingle from '~/components/DatePicker/MHCalendarSingle.vue';
import TextField from '~/components/form/TextField.vue';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import OverlayBtmSticky from '~/components/OverlayBtmSticky.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import yup from 'mh-yup';
import { i18nGlobal } from '~/logic/i18n.js';
import { MHScrollToElement } from '~/logic/helpers/dom.js';

const props = defineProps({
	name: { type: String, required: true },
	
	modelValue: { type: String, default: null },
	defaultValue: { type: String, default: null },
	variant: { type: String, default: '' },
	
	closeAfterSelectDate: { type: Boolean, default: true },
	
	canSwitchView: { type: Boolean, default: true },
	canNavigateMonth: { type: Boolean, default: true },
	
	displayYear: { type: Array, default: () => ['t-120', 't+1'] },
	
	// restrictions
	/* 
	minDate / maxDate prop:
		· Can support absolute date or relative date
		
		Absolute dates
		· Format is 'YYYY-MM-DD'
		E.g. '2022-11-26'
		
		Relative dates
		· t = today
		· Only support + / -
		· No whitespace allowed.
		· Must end with d / m / y
		E.g.
			't-10d' <-- today minus 10 days
			't-2m' <-- today minus 2 months
			't+1y' <-- today plus 1 year
	*/
	minDate: { type: String, default: null },
	maxDate: { type: String, default: null },
	
	// disabledDates iterates over the Array for every date. That is slow, avoid if possible.
	disabledDates: { type: Array, default: null },
	
	// disablePastDates will be overwritten by minDate
	disablePastDates: { type: Boolean, default: false },
	// disableFutureDates will be overwritten by maxDate
	disableFutureDates: { type: Boolean, default: false },
	
	rootAttrs: { type: Object, default: () => ({}) },
	
	required: { type: Boolean, default: false },
	requiredErrorMsg: { type: String, default: i18nGlobal.t('This field is required') },
	
	isHideTodayIndicator: { type: Boolean, default: false },

	isReadOnly: { type: Boolean, default: false },
	
	disableAutoCompleteForAmal: { type: Boolean, default: false },
});
const emit = defineEmits([
	'update:modelValue',
]);

const isShowFloatingPanel = ref(false);
const calendarEl = ref(null);
const internalValue = useInternalValue('modelValue', {
	...(dayjs(props.defaultValue).isValid() ? { defaultValue: dayjs(props.defaultValue) } : null),
	beforeEmitTransform (value) {
		if (value instanceof dayjs) return value.format('YYYY-MM-DD');
		return null;
	},
	propReceivedTransform (value) {
		const transformed = dayjs(value);
		if (!transformed.isValid()) {
			return null;
		}
		return transformed;
	},
});

const inputEl = ref(null);
const rootEl = ref(null);


const handleRootFocusedWithin = () => {
	//
};
const handleRootBlurredWithin = () => {
	if (isMobileViewport.value) return;
	hideFloatingPanel();
};

const showFloatingPanel = () => {
	if (props.isReadOnly) return;
	if (isMobileViewport.value) {
		// mobile
		setShowPickerOverlay(true);
		return;
	} else {
		// desktop
		isShowFloatingPanel.value = true;
	}
};
const hideFloatingPanel = () => {
	if (isMobileViewport.value) {
		// mobile
		setShowPickerOverlay(false);
		return;
	} else {
		// desktop
		isShowFloatingPanel.value = false;
	}
};

const handleSelectDate = (dayjsInstance) => {
	internalValue.value = dayjsInstance;
	if (dayjsInstance && props.closeAfterSelectDate) {
		hideFloatingPanel();
		rootEl.value.focus();
	}
};

const handleRootKeyEsc = (event) => {
	hideFloatingPanel();
};


const showPickerOverlay = ref(false);

const setShowPickerOverlay = (flag) => {
	showPickerOverlay.value = flag;
};

const computedMinDate = computed(() => {
	if (props.minDate) return props.minDate;
	if (props.disablePastDates) return 't';
	return null;
});
const computedMaxDate = computed(() => {
	if (props.maxDate) return props.maxDate;
	if (props.disableFutureDates) return 't';
	return null;
});


// const validation = computed(() => {
// 	if (!props.required) return null;
// 	return yup.string().required(props.requiredErrorMsg).nullable();
// });
const validationFunc = (value) => {
	if (!props.required) return true;
	
	const yupSchema = yup.string().required(props.requiredErrorMsg).nullable();
	
	try {
		yupSchema.validateSync(value);
		return true;
	} catch (err) {
		return err.errors[0];
	}
};


const focus = () => {
	inputEl.value?.focus();
};

const handleInputMounted = () => {
	inputEl.value.inputEl._mh_invalidFocus = (el) => {
		MHScrollToElement(rootEl.value, { additionalTopOffset: 60 });
		rootEl.value.focus({ preventScroll: true });
	};
};


defineExpose({
	focus,
	inputEl,
});

</script>


<template>
<div
	ref="rootEl"
	v-focus-within
	class="DatePicker"
	tabindex="-1"
	v-bind="props.rootAttrs"
	:data-variant="props.variant"
	@focus-within="handleRootFocusedWithin"
	@blur-within="handleRootBlurredWithin"
	@keydown.esc.prevent="handleRootKeyEsc"
>
	<div
		class="inner-wrapper flex relative flex-col h-full"
	>
		<TextField
			ref="inputEl"
			:class="{
				'is-active': isShowFloatingPanel,
			}"
			:modelValue="internalValue?.format('DD MMM YYYY') ?? null"
			:placeholder="props.placeholder"
			:name="props.name"
			:ariaLabel="props.ariaLabel"
			:disabled="props.isReadOnly"
			:readonly="props.variant === 'mhe' ? true : props.readonly"
			:isFocused="isShowFloatingPanel"
			:validation="validationFunc"
			:variant="props.variant.includes('booking-widget') ? 'booking-widget' : props.variant"
			:autocomplete="props.disableAutoCompleteForAmal ? 'off' : ''"
			v-bind="$attrs"
			@focus="showFloatingPanel"
			@click="showFloatingPanel"
			@keydown.down.prevent="calendarEl.handleArrowNavigation('down')"
			@keydown.right.prevent="calendarEl.handleArrowNavigation('right')"
			@keydown.up.prevent="calendarEl.handleArrowNavigation('up')"
			@keydown.left.prevent="calendarEl.handleArrowNavigation('left')"
			@keydown.backspace.delete.prevent="internalValue = null"
			@vue:mounted="handleInputMounted"
		>
			<template v-for="(_, slotName) in $slots" #[slotName]="slotData">
				<slot :name="slotName" v-bind="{ ...slotData }"></slot>
			</template>
			
		</TextField>
	</div>
	
	
	<div
		v-if="isShowFloatingPanel && !isMobileViewport"
		class="floating-panel shadow-type-a border-2 border-neutral-grey-light"
		role="dialog"
		aria-modal="true"
		aria-label="Choose dates"
	>
		<MHCalendarSingle
			ref="calendarEl"
			class=""
			:canSwitchView="props.canSwitchView"
			:canNavigateMonth="props.canNavigateMonth"
			:minDate="computedMinDate"
			:maxDate="computedMaxDate"
			:disabledDates="props.disabledDates"
			:displayYear="props.displayYear"
			:modelValue="internalValue"
			:isHideTodayIndicator="props.isHideTodayIndicator"
			@select-date="handleSelectDate"
		/>
	</div>
	
	<OverlayBtmSticky
		class="datepicker-mobile-overlay"
		:isVisible="showPickerOverlay"
		@update:is-visible="setShowPickerOverlay($event)"
	>
		<div class="pl-5 sticky top-0 bg-white z-1000 pb-4 max-w-[360px] mx-auto">
			<h6 class="font-bold line-clamp-1">
				<slot name="mobile-title"><slot></slot></slot>
			</h6>
		</div>
		<div
			class="floating-panel shadow-type-a border-2 border-neutral-grey-light"
			role="dialog"
			aria-modal="true"
			aria-label="Choose dates"
		>
			<MHCalendarSingle
				ref="calendarEl"
				class="max-w-[360px] mx-auto"
				:canSwitchView="props.canSwitchView"
				:canNavigateMonth="props.canNavigateMonth"
				:minDate="computedMinDate"
				:maxDate="computedMaxDate"
				:disabledDates="props.disabledDates"
				:displayYear="props.displayYear"
				:modelValue="internalValue"
				:isHideTodayIndicator="props.isHideTodayIndicator"
				@select-date="handleSelectDate"
			/>
		</div>
	</OverlayBtmSticky>
</div>
</template>


<style lang="scss">
.OverlayBtmSticky.datepicker-mobile-overlay {
	.overlay-content {
		/* margin: 0; */
	}
	.floating-panel {
		position: static;
		border: 0;
	}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.DatePicker {
	--textfield-height: 54px;
	position: relative;
	
	&:focus {
		outline: 0;
		border: 0;
	}
	
	:deep(.input) {
		font-weight: 600;
		color: var(--primary-blue-base);
		cursor: pointer;
		&:disabled {
			color: var(--neutral-grey-base);
		}
	}
	
	:deep(.TextField .inner-wrapper) {
		cursor: pointer;
	}
	
	&[data-variant="booking-widget"] {
		--textfield-height: 64px;
	}
}

.floating-panel {
	position: absolute;
	background-color: var(--floating-calendar-bg-color, white);
	border-radius: 12px;
	z-index: 100;
	left: 0;
	top: calc(var(--textfield-height) + 4px);
	width: 100%;
}

</style>

