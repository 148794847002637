<script>
export default {
	name: 'MABADefaultLayout',
	expose: [],
	inheritAttrs: false,
};
</script>


<script setup>
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';
import { generateXfPath } from '@/logic/aem/generate-xf-path';

const siteName = window.siteName;

</script>


<template>
<div class="MABADefaultLayout flex flex-col min-h-screen" data-layout="MABADefault">
	<ExperienceFragment
		class="global-header-notification"
		:xfPath="generateXfPath({ siteName, xfComponentId: 'header-notification' })"
	/>
	<div class="sticky-spacer h-$headerNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header"
		:xfPath="generateXfPath({ siteName, xfComponentId: 'header' })"
	/>
	
	<div id="content"></div>
	<main>
		<slot></slot>
	</main>
	
	<div class="floating-bottom-right-container">
		<BtnBackToTop />
	</div>
	
	<ExperienceFragment
		class="global-cookies-notification"
		:xfPath="generateXfPath({ siteName, xfComponentId: 'cookies-notification' })"
	/>
	<ExperienceFragment
		class="global-footer"
		:xfPath="generateXfPath({ siteName, xfComponentId: 'footer' })"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

</style>
