<script>
export default {
	name: 'MobileMainMenu',
	inheritAttrs: false,
};
</script>

<script setup>
import OverlayDrawer from '~/components/OverlayDrawer.vue';
import MobileDrawerHeader from '~/components/AppHeader/MobileDrawerHeader.vue';
import MobileCountrySelector from '~/components/AppHeader/MobileCountrySelector.vue';
import TransitionSimpleSlideDown from '~/components/transition/TransitionSimpleSlideDown.vue';
import iconPlus from '~icons/far/plus';
import iconMinus from '~icons/far/minus';
import MobileMainMenuFooter from '~/components/AppHeader/MobileMainMenuFooter.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
const { pageProperties } = useGlobalAEMState();

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	mobileNavigationList: { type: Array, default: () => [] },
	headerNavigationList: { type: Array, default: () => [] },
	mobileSelectedCountryLabel: { type: String, default: '' },
	mobileAllCountriesLabel: { type: String, default: '' },
	mobileSearchInputPlaceholder: { type: String, default: '' },
	mobileLogo1LightSrc: { type: String, default: '' },
	mobileLogo1LightAltText: { type: String, default: '' },
	mobileIsOpenInNewTabLogo1: { type: Boolean, default: false },
	mobileIsHideLogo1: { type: Boolean, default: false },
	mobileCountryLanguageItems: { type: Object, default: () => ({}) },
	mobileFooter: { type: Object, default: () => ({}) },
	isHideMobileSearch: { type: Boolean, default: false },
	isHideAccountIcon: { type: Boolean, default: false },
	componentIdAA: { type: String, default: '' },
	countryLanguageRedirectBaseUrl: { type: String, default: '' },
});
const emit = defineEmits([
	'update:isVisible',
	'update:currentActiveAccordion',
]);

const showMobileCountrySelector = ref(false);
const expandedLevelTwo = ref('');
const mobileFooterRef = ref(null);

const toggleAccordion = (itemName) => {
	if (expandedLevelTwo.value === itemName) {
		expandedLevelTwo.value = '';
	} else {
		expandedLevelTwo.value = itemName;
	}

	mobileFooterRef.value.setFooterCurrentAccordion(itemName);
};

/*
*	Transform all countries and language into array based on country keys
*
*	Filter out selected country and language object keys
* 	Return an array of countries and languages
*
*/
const mobileAllCountriesAndLanguagesTransform = computed(() => {
	const mobileAllCountriesAndLanguages = props.mobileCountryLanguageItems.allCountriesAndLanguages || {};

	const countryKeys = Object.keys(mobileAllCountriesAndLanguages).filter((country) => country !== props.mobileCountryLanguageItems.currentCountry);

	if (Object.keys(mobileAllCountriesAndLanguages).length !== 0) {
		return countryKeys.map((country) => {
			return props.mobileCountryLanguageItems.allCountriesAndLanguages[country];
		});
	}

	return null;
});

const selectedCountryAndLanguageTransform = computed(() => {
	const currentCountry = props.mobileCountryLanguageItems.currentCountry;
	const allCountriesAndLanguagesItems = props.mobileCountryLanguageItems.allCountriesAndLanguages;
	
	if (!allCountriesAndLanguagesItems) return null;
	
	// Array format is 'Amal' site only
	if (Array.isArray(allCountriesAndLanguagesItems)) {
		return allCountriesAndLanguagesItems.find((item) => {
			return item.countryCode === currentCountry;
		}) ?? null;
	}
	
	// original logic (MH, MHH, etc...)
	if (Object.keys(allCountriesAndLanguagesItems).length !== 0) {
		return {
			country: allCountriesAndLanguagesItems[currentCountry].pageTitle,
			languages: allCountriesAndLanguagesItems[currentCountry].languages,
		};
	}

	return null;
});

const handleNavigationItemClick = (event) => {
	emit('update:isVisible', false);
};

</script>

<template>
<OverlayDrawer
	:isVisible="props.isVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	v-bind="$attrs"
	@update:is-visible="$emit('update:isVisible', $event)"
>
	<div class="MobileMainMenu overflow-y-auto">
		<MobileDrawerHeader
			class="sticky top-0 bg-$overlay-container-bg-color z-10"
			:logoImgSrc="props.mobileLogo1LightSrc"
			:logoImgAlt="props.mobileLogo1LightAltText"
			:logoOpenInNewTab="props.mobileIsOpenInNewTabLogo1"
			:logoIsHideMobileLogoImg="props.mobileIsHideLogo1"
			:drawerIsHideMobileSearch="props.isHideMobileSearch"
			:drawerIsHideAccountIcon="props.isHideAccountIcon"
			:componentIdAA="props.componentIdAA"
			@click:header-x="$emit('update:isVisible', false)"
		/>

		<div class="px-6 mt-4 xs:pb-20">
			<nav>
				<ul class="menu-list">

					<!-- Hamburger menu -->
					<template
						v-for="(mobileNavigationItem, mobileNavigationItemIndex) in props.mobileNavigationList"
						:key="mobileNavigationItemIndex"
					>
						<li
							v-if="mobileNavigationItem.hamburgerL2Menu && mobileNavigationItem.hamburgerL2Menu.length"
							class="menu-item"
							:class="{ 'is-expanded': expandedLevelTwo === `${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}` }"
						>
							<button
								v-aa="[
									{
										clickName: mobileNavigationItem.l1TitleLabel,
										clickComponentType: 'Button',
										componentName: 'AppHeader',
										componentID: props.componentIdAA,
									},
									{
										name: mobileNavigationItem.l1TitleLabel,
										type: 'other',
									},
								]"
								type="button"
								class="w-full text-left px-0 py-4 flex items-center"
								@click="toggleAccordion(`${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}`)"
							>
								<div>
									<span class="font-semibold">{{ mobileNavigationItem.l1TitleLabel }}</span>
									<span
										v-if="mobileNavigationItem.newLabel"
										class="level1-badge-mobile ml-2 rounded border border-current font-bold py-1 px-2 uppercase"
									>
										{{ $t('New') }}
									</span>
								</div>
								<component :is="expandedLevelTwo === `${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}` ? iconMinus : iconPlus" class="ml-auto fill-current mr-1 rtl:ml-0 rtl:mr-auto" />
							</button>
							<TransitionSimpleSlideDown>
								<div v-if="expandedLevelTwo === `${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}`" class="overflow-hidden">
									<hr class="separator" />
									<div class="px-4">
										<ul>
											<li
												v-for="(mobileNavigationL2Item, mobileNavigationL2ItemIndex) in mobileNavigationItem.hamburgerL2Menu"
												:key="mobileNavigationL2ItemIndex"
											>
												<AppHyperlink
													v-aa="[
														{
															clickName: mobileNavigationL2Item.l2TitleLabel,
															clickComponentType: 'URL',
															componentName: 'AppHeader',
															componentID: props.componentIdAA,
														},
														{
															name: mobileNavigationL2Item.l2TitleLabel,
															type: 'other',
														},
													]"
													:href="mobileNavigationL2Item.l2TitlePagePath"
													class="block lg:py-[15px] text-neutral-white-base hover:text-neutral-white-base"
													:showExternalLinkIcon="mobileNavigationL2Item.externalLink"
													@click="handleNavigationItemClick"
												>
													{{ mobileNavigationL2Item.l2TitleLabel }}
												</AppHyperlink>
											</li>
										</ul>
									</div>
								</div>
							</TransitionSimpleSlideDown>
						</li>

						<li
							v-else
							class="menu-item"
						>
							<!-- <button
								type="button"
								class="w-full text-left px-0 py-4 flex items-center"
							>{{ mobileNavigationItem.l1TitleLabel }}</button> -->
							<AppHyperlink
								v-aa="[
									{
										clickName: mobileNavigationItem.l1TitleLabel,
										clickComponentType: 'URL',
										componentName: 'AppHeader',
										componentID: props.componentIdAA,
									},
									{
										name: mobileNavigationItem.l1TitleLabel,
										type: 'other',
									},
								]"
								class="w-full text-left px-0 py-4 flex items-center text-neutral-white-base hover:text-neutral-white-base menu-item-without-child"
								:href="mobileNavigationItem.l1TitlePagePath"
								:target="mobileNavigationItem.openInNewTab ? '_blank' : '_self'"
								@click="handleNavigationItemClick"
							>
								<div>
									<span class="level-1-title-label font-semibold">{{ mobileNavigationItem.l1TitleLabel }}</span>
									<span
										v-if="mobileNavigationItem.newLabel"
										class="level1-badge-mobile ml-2 rounded border border-current font-bold py-1 px-2 uppercase"
									>
										{{ $t('New') }}
									</span>
								</div>
							</AppHyperlink>
						</li>
					</template>

					<!-- Header navigation -->
					<template
						v-for="(mobileNavigationItem, mobileNavigationItemIndex) in props.headerNavigationList"
						:key="mobileNavigationItemIndex"
					>
						<template v-if="!mobileNavigationItem.hideInMobHamMenu">
							<li
								v-if="mobileNavigationItem.headerL2Navigation && mobileNavigationItem.headerL2Navigation.length"
								class="menu-item"
								:class="{ 'is-expanded': expandedLevelTwo === `${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}` }"
							>
								<button
									v-aa="[
										{
											clickName: mobileNavigationItem.l1TitleLabel,
											clickComponentType: 'Button',
											componentName: 'AppHeader',
											componentID: props.componentIdAA,
										},
										{
											name: mobileNavigationItem.l1TitleLabel,
											type: 'other',
										},
									]"
									type="button"
									class="w-full text-left px-0 py-4 flex items-center"
									@click="toggleAccordion(`${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}`)"
								>
									<div>
										<span class="font-semibold">{{ mobileNavigationItem.l1TitleLabel }}</span>
										<span
											v-if="mobileNavigationItem.newLabel"
											class="level1-badge-mobile ml-2 rounded border border-current font-bold py-1 px-2 uppercase"
										>
											{{ $t('New') }}
										</span>
									</div>
									<component :is="expandedLevelTwo === `${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}` ? iconMinus : iconPlus" class="ml-auto fill-current mr-1 rtl:ml-0 rtl:mr-auto" />
								</button>
								<TransitionSimpleSlideDown>
									<div v-if="expandedLevelTwo === `${mobileNavigationItem.l1TitleLabel} ${mobileNavigationItemIndex}`" class="overflow-hidden">
										<hr class="separator" />
										<div class="px-4">
											<ul>
												<li v-if="mobileNavigationItem.l1TitleCtaLabel">
													<AppHyperlink
														v-aa="[
															{
																clickName: mobileNavigationItem.l1TitleCtaLabel,
																clickComponentType: 'URL',
																componentName: 'AppHeader',
																componentID: props.componentIdAA,
															},
															{
																name: mobileNavigationItem.l1TitleCtaLabel,
																type: 'other',
															},
														]"
														class="block lg:py-[15px] text-neutral-white-base hover:text-neutral-white-base"
														:href="mobileNavigationItem.l1TitlePagePath"
														:showExternalLinkIcon="false"
													>
														<span class="footer-top-link-text">{{ mobileNavigationItem.l1TitleCtaLabel }}</span>
														<icon-far-arrow-right class="external-link-icon fill-white inline-block >md:hidden ml-3 rtl:mr-3 text-base transform rtl:-rotate-180" />
													</AppHyperlink>
												</li>

												<li
													v-for="(mobileNavigationL2Item, mobileNavigationL2ItemIndex) in mobileNavigationItem.headerL2Navigation"
													:key="mobileNavigationL2ItemIndex"
												>
													<AppHyperlink
														v-aa="[
															{
																clickName: mobileNavigationL2Item.l2TitleLabel,
																clickComponentType: 'URL',
																componentName: 'AppHeader',
																componentID: props.componentIdAA,
															},
															{
																name: mobileNavigationL2Item.l2TitleLabel,
																type: 'other',
															},
														]"
														:href="mobileNavigationL2Item.l2TitlePagePath"
														class="block py-[15px] text-neutral-white-base hover:text-neutral-white-base"
														:showExternalLinkIcon="mobileNavigationL2Item.externalLink"
													>
														{{ mobileNavigationL2Item.l2TitleLabel }}
													</AppHyperlink>
												</li>
											</ul>
										</div>
									</div>
								</TransitionSimpleSlideDown>
							</li>

							<li
								v-else
								class="menu-item"
							>
								<!-- <button
									type="button"
									class="w-full text-left px-0 py-4 flex items-center"
								>{{ mobileNavigationItem.l1TitleLabel }}</button> -->
								<AppHyperlink
									v-aa="[
										{
											clickName: mobileNavigationItem.l1TitleLabel,
											clickComponentType: 'URL',
											componentName: 'AppHeader',
											componentID: props.componentIdAA,
										},
										{
											name: mobileNavigationItem.l1TitleLabel,
											type: 'other',
										},
									]"
									class="w-full text-left px-0 py-4 flex items-center text-neutral-white-base hover:text-neutral-white-base menu-item-without-child"
									:href="mobileNavigationItem.l1TitlePagePath"
									:target="mobileNavigationItem.openInNewTab ? '_blank' : '_self'"
								>
									<div>
										<span class="level-1-title-label font-semibold">{{ mobileNavigationItem.l1TitleLabel }}</span>
										<span
											v-if="mobileNavigationItem.newLabel"
											class="level1-badge-mobile ml-2 rounded border border-current font-bold py-1 px-2 uppercase"
										>
											{{ $t('New') }}
										</span>
									</div>
								</AppHyperlink>
							</li>
						</template>
					</template>

					<!-- <li class="menu-item">
						<button
							type="button"
							class="w-full text-left px-0 py-4 flex items-center"
						>Menu Item 1</button>
					</li> -->
				</ul>
			</nav>
			<footer>
				<MobileMainMenuFooter
					ref="mobileFooterRef"
					v-model:currentActiveAccordion="expandedLevelTwo"
					:generalTab="props.mobileFooter.generalTab"
					:exerciseYourRightsTab="props.mobileFooter.exerciseYourRightsTab"
					:subsidiaryTab="props.mobileFooter.subsidiaryTab"
					:socialMediaTab="props.mobileFooter.socialMediaTab"
					:tncTab="props.mobileFooter.tncTab"
					:copyrightTab="props.mobileFooter.copyrightTab"
					:componentIdAA="props.componentIdAA"
				/>
			</footer>
		</div>

		<div class="btm-sticky min-h-18 bg-$overlay-container-bg-color px-6 z-10">
			<button
				v-aa="[
					{
						clickName: props.mobileCountryLanguageItems.currentLanguage,
						clickComponentType: 'Button',
						componentName: 'AppHeader',
						componentID: props.componentIdAA,
					},
					{
						name: props.mobileCountryLanguageItems.currentLanguage,
						type: 'other',
					},
				]"
				type="button"
				class="px-0 flex items-center w-full px-0"
				@click="showMobileCountrySelector = true"
			>
				<div class="flex items-center gap-2">
					<img :src="pageProperties.countryFlag" width="24" height="24" />
					<span class="text-sm font-semibold uppercase">{{ props.mobileCountryLanguageItems.currentLanguage }}</span>
				</div>
				<icon-far-chevron-right class="ml-auto: rtl:ml-0 transform rtl:-rotate-180 fill-current" />
			</button>
		</div>
	</div>
</OverlayDrawer>

<MobileCountrySelector
	v-if="mobileAllCountriesAndLanguagesTransform"
	class="mobile-country-selector-backdrop"
	:isVisible="showMobileCountrySelector"
	:mobileSelectedCountryLabelSelector="props.mobileSelectedCountryLabel"
	:mobileAllCountriesLabelSelector="props.mobileAllCountriesLabel"
	:mobileCountryLanguage="props.mobileCountryLanguageItems"
	:mobileAllCountriesAndLanguages="mobileAllCountriesAndLanguagesTransform"
	:mobileSelectedCountryAndLanguage="selectedCountryAndLanguageTransform"
	:countryLanguageRedirectBaseUrl="props.countryLanguageRedirectBaseUrl"
	:headerIsHideMobileSearch="props.isHideMobileSearch"
	:headerIsHideAccountIcon="props.isHideAccountIcon"
	:headerLogoImgSrc="props.mobileLogo1LightSrc"
	:headerLogoImgAlt="props.mobileLogo1LightAltText"
	:headerLogoOpenInNewTab="props.mobileIsOpenInNewTabLogo1"
	:headerLogoIsHideMobileLogoImg="props.mobileIsHideLogo1"
	@update:is-visible="showMobileCountrySelector = $event"
	@click:header-x="showMobileCountrySelector = false; $emit('update:isVisible', false)"
	@click:btn-back="showMobileCountrySelector = false"
/>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MobileMainMenu {
	max-height: 100vh;
	max-height: 100svh; // <-- small viewport height
}

.btm-sticky {
	position: sticky;
	bottom: 0;
	border-top: 1px solid #556084;
	display: flex;
	@media #{var.$query-max-xs} {
		position: fixed;
		left: 0;
		right: 0;
	}
}

.menu-item {
	@apply px-2;
	transition: background-color 0.15s;

	&.is-expanded {
		@apply rounded-xl bg-primary-blue-extralight bg-opacity-16;
	}

	& > button {
		border-bottom: 1px solid #303751;
	}

	& > .menu-item-without-child {
		border-bottom: 1px solid #303751;
	}
}

.separator {
	background-color: #556084;
}

.level1-badge-mobile {
	font-size: 10px;
	line-height: 1;
	width: min-content;
}
</style>
