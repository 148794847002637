<script>
export default {
	name: 'TextField',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import Tooltip from '~/components/Tooltip.vue';
import useOptionalProp from '~/logic/composables/useOptionalProp.js';
import useFieldValidation from '~/logic/composables/useFieldValidation.js';
import useKeyInputRestriction from '~/logic/composables/useKeyInputRestriction.js';
import useGenerateIdentifier from '~/logic/composables/useGenerateIdentifier.js';
import useFormAccordionStatus from '~/logic/composables/useFormAccordionStatus.js';


const props = defineProps({
	name: { type: String, default: '' }, // Note: if name is not provided, then validation would not work
	ariaLabel: { type: String, default: '' },
	
	modelValue: { type: [String, Number], default: null },
	
	placeholder: { type: String, default: null },
	disabled: { type: Boolean, default: false },
	readonly: { type: Boolean, default: false },
	prefix: { type: String, default: null },
	showBtnClear: { type: Boolean, default: false },
	
	variant: { type: String, default: '' },
	rootAttrs: { type: Object, default: () => ({}) },
	
	isFocused: { type: Boolean, default: null },
	highlightField: { type: Boolean, default: false },
	
	// restrictions
	disallowedKeys: { type: [ String, Array, RegExp ], default: null },
	allowedKeys: { type: [ String, Array, RegExp ], default: null },
	validation: {
		type: [Object, Function],
		default: null,
		// only accepts Yup object, or a function
		validator: (val) => ((val === null) || (val instanceof yup.BaseSchema) || (val instanceof Function)),
	},
	
	// transformation
	/* 
		Note: for now, we allow one special case: "autoTransformToUppercase".
		If in the future we need more transformation, we might need to enhance this to take in a function
	*/
	autoTransformToUppercase: { type: Boolean, default: false },
	
	autoTransformToTitlecase: { type: Boolean, default: false },

	showAsteriskSymbol: { type: Boolean, default: false },
});
const emit = defineEmits([
	'update:modelValue',
	'update:isFocused',
]);

const inputEl = ref(null);

const internalIsFocused = useOptionalProp('isFocused', {
	defaultValue: false,
});
const focus = () => {
	inputEl.value?.focus();
};

const siteName = window.siteName;

const computedValidation = computed(() => {
	if (!(props.validation instanceof yup.BaseSchema)) return props.validation;
	/* 
		If validation prop is a yup schema, then enhance the schema by forcing empty string '' to validate as null
		https://github.com/jquense/yup/issues/1086
		https://github.com/BinaryStudioAcademy/bsa-2022-autoline/pull/201
	*/
	return props.validation.nullable().transform((value) => (value || null)); // always tran
});

const {
	onInput,
	meta,
	errors,
	setTouched,
	isRequired,
	hasValidationError,
	internalValue,
	validate,
	setErrors,
	resetField,
} = useFieldValidation({
	name: toRef(props, 'name'),
	validation: computedValidation,
	modelValue: toRef(props, 'modelValue'),
	...(props.autoTransformToUppercase ? { valueTransform: (v) => v?.toUpperCase?.() ?? v } : null),
	...(props.autoTransformToTitlecase ? { valueTransform: (v) => v?.replace( /\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() ) ?? v } : null),
});


const { onKeypress, onPaste } = useKeyInputRestriction({
	allowedKeys: toRef(props, 'allowedKeys'),
	disallowedKeys: toRef(props, 'disallowedKeys'),
});

const componentIdentifier = props.name ?? (Math.random() * 10);

const {
	errorMsgId,
	describedbyId,
	detailsId,
} = useGenerateIdentifier(componentIdentifier);


useFormAccordionStatus(props.name, {
	required: props.validation instanceof Function ? props.validation() !== true : false,
	value: computed(() => internalValue.value),
	error: computed(() => hasValidationError.value),
});

defineExpose({
	inputEl,
	focus,
	hasValidationError,
	errors,
	meta,
	validate,
	setTouched,
	setErrors,
	resetField,
});

</script>

<template>
<div
	class="TextField flex flex-col"
	:class="{
		'is-disabled': props.disabled,
		'is-readonly': props.readonly,
		'is-focused': internalIsFocused,
		'has-validation-error': hasValidationError && meta.touched,
		'highlight-field': props.highlightField,
	}"
	:data-use-theme="siteName"
	:data-variant="props.variant"
	v-bind="props.rootAttrs"
>
	<div class="inner-grand-container flex">
		<div class="inner-container flex flex-grow h-full">
			<label class="inner-wrapper flex-grow relative flex px-5 pt-5.5 pb-2.5">
				<span
					v-if="props.prefix"
					class="value-prefix leading-none relative mr-1 rtl:mr-0 rtl:ml-1"
					:class="{
						'is-visible': internalIsFocused || internalValue,
					}"
				>{{ props.prefix }}</span>
				
				<div class="prefix-icon-wrapper empty:hidden">
					<slot name="prefix-icon"></slot>
				</div>
				
				<div class="flex-grow flex">
					<input
						ref="inputEl"
						:value="internalValue"
						:name="props.name || null"
						class="input w-full"
						:class="{
							'is-focus': internalIsFocused,
						}"
						type="text"
						placeholder="‎ "
						:disabled="props.disabled"
						:readonly="props.readonly"
						:tabindex="props.variant?.includes('input-hidden') ? -1 : null"
		
						:aria-label="props.ariaLabel"
						:aria-placeholder="props.placeholder"
						:aria-invalid="hasValidationError ? 'true' : null"
						:aria-errormessage="errorMsgId"
						:aria-describedby="$slots['short-description'] ? describedbyId : null"
						:aria-details="$slots['tooltip-content'] ? detailsId : null"
						:aria-hidden="props.variant?.includes('input-hidden') ? 'true' : null"
						:required="isRequired"
		
						v-bind="$attrs"
		
						@keypress="onKeypress"
						@paste="onPaste"
						@input="onInput"
						@focus="internalIsFocused = true"
						@blur="setTouched(true); internalIsFocused = false"
					/>
					<div v-if="hasValidationError" class="sr-only">{{ $t('This field is invalid') }}</div>
					<div class="text-label">
						<slot>{{ props.ariaLabel }}</slot>
						<span
							v-if="props.showAsteriskSymbol"
							class="required-asterisk"
						>*</span>
					</div>
					<div class="text-placeholder" aria-hidden="true">
						<slot name="placeholder">{{ props.placeholder }}</slot>
					</div>
				</div>
				<button
					v-show="internalValue && props.showBtnClear"
					type="button"
					class="btn-clear rounded-full py-2 m-auto"
					:aria-label="$t('Clear field')"
					@click="internalValue = ''; inputEl.focus()"
				>
					<icon-fas-circle-xmark class="" aria-hidden="true" />
				</button>
			</label>
		
			<div class="suffix-wrapper empty:hidden">
				<slot name="suffix"></slot>
			</div>
		</div>
		
		<div v-if="$slots['tooltip-content']" class="tooltip-content-wrapper flex items-center ml-2">
			<Tooltip>
				<template #default>
					<icon-fas-circle-question class="fill-primary-blue-base" />
				</template>
				<template #mobile-title>
					<slot>{{ props.ariaLabel }}</slot>
				</template>
				<template #tooltip-content>
					<div :id="detailsId">
						<slot name="tooltip-content"></slot>
					</div>
				</template>
			</Tooltip>
		</div>
	</div>
	
	<div
		:id="describedbyId"
		class="short-description-wrapper w-full text-sm empty:hidden"
	>
		<slot name="short-description"></slot>
	</div>
	<div
		:id="errorMsgId"
		class="error-msg-container leading-tight text-semantic-red-base text-sm w-full mt-1 empty:hidden"
	>
		<slot
			v-if="hasValidationError && meta.touched"
			name="error-messages"
			v-bind="{ meta, errors, internalValue }"
		>
			<span v-html-sanitize="errors[0]"></span>
		</slot>
	</div>
</div>

</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';


.TextField {
	
	&.is-focused {
		.inner-container {
			--borderColor: var(--primary-blue-base);
		}
	}
	&.has-validation-error {
		.inner-container {
			--labelColor: var(--semantic-red-base);
			--borderColor: var(--semantic-red-light);
			/* --inputValueColor: var(--semantic-red-base); */
			/* --prefixColor: var(--semantic-red-base); */
			
			&:hover {
				--borderColor: #{var.$semantic-red-base-50-opacity};
			}
		}
		.text-label {
			--labelColor: var(--semantic-red-base) !important;
		}
		.short-description-wrapper {
			color: var(--semantic-red-base);
		}
		
		&.is-focused {
			.inner-container {
				--borderColor: var(--semantic-red-base);
			}
		}
	}

	&.highlight-field {
		.inner-container {
			--borderColor: var(--semantic-red-base);
		}
	}
}

.inner-container {
	--borderColor: var(--neutral-grey-light);
	--bgColor: var(--neutral-grey-ultralight);
	--labelColor: var(--neutral-grey-ultradark);
	--placeholderColor: var(--neutral-grey-ultradark);
	--inputValueColor: var(--text-color);
	--prefixColor: var(--primary-blue-base);
	position: relative;
	border-radius: 12px;
	background-color: var(--bgColor);
	border: 2px solid var(--borderColor);
	transition-property: border-color;
	transition-duration: 0.2s;
	
	&:hover {
		--borderColor: var(--neutral-grey-base);
	}
}


@supports selector(.parent:has(.child)) {
	.inner-wrapper {
		cursor: text;
		
		&:has(.input[readonly]) {
			cursor: default;
		}
		&:has(.input[disabled]) {
			cursor: default;
		}
	}
}

.value-prefix {
	opacity: 0;
	transform: translate(0, 10px);
	transition: all 0.18s;
	color: var(--prefixColor);
	font-weight: 600;
	user-select: none;
	top: 0.5px;
	
	&.is-visible {
		opacity: 1;
		transform: translate(0, 0);
	}
}

.TextField[data-use-theme="firefly"] {
	.value-prefix {
		color: var(--secondary-firefly-orange-extradark);
		font-weight: 600;
	}
}

.input {
	line-height: 1;
	border: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	background-color: transparent;
	outline: 0;
	border-radius: 0;
	color: var(--inputValueColor);
	
	&:focus ~ .text-label,
	&.is-focus ~ .text-label,
	&:not(:placeholder-shown) ~ .text-label {
		--labelColor: var(--neutral-grey-extradark);
		font-size: 12px;
		transform: translate(1px, -0.7rem);
	}
	
	&:focus:placeholder-shown ~ .text-placeholder,
	&.is-focus:placeholder-shown ~ .text-placeholder {
		opacity: 1;
		transform: translate(1px, 0px);
	}
	
	&:disabled {

		color: var(--neutral-grey-base);
		
		~ .text-label, ~ .text-placeholder {
			--borderColor: var(--neutral-grey-light);
			--bgColor: var(--neutral-grey-ultralight);
			--labelColor: var(--neutral-grey-base);
			--placeholderColor: var(--neutral-grey-base);
		}
	}
	
	&[readonly] {
		cursor: default !important;
	}
}

.custom-mhe-lockfields {

	.inner-container {
		--bgColor: var(--neutral-grey-light);

		.input {

			&:disabled {
				color: var(--neutral-grey-ultradark);
				
				~ .text-label, ~ .text-placeholder {
					--borderColor: var(--neutral-grey-light);
					--bgColor: var(--neutral-grey-ultradark);
					--labelColor: var(--neutral-grey-ultradark);
					--placeholderColor: var(--neutral-grey-base);
				}
			}
		}

		input[name="date-of-birth"] {
			&:disabled {
				color: var(--primary-blue-base);
			}
		}
	}
}

.text-label {
	position: absolute;
	line-height: 1;
	transition: all 0.18s;
	transform-origin: 0 0;
	font-weight: 600;
	pointer-events: none;
	top: 0;
	bottom: 0;
	height: 1em;
	margin-bottom: auto;
	margin-top: auto;
	color: var(--labelColor);
	@apply left-5; // <-- match the padding left of rootEl

	html[dir="rtl"] & {
		@apply left-0 right-5;
	}
}
.text-placeholder {
	position: absolute;
	line-height: 1;
	opacity: 0;
	transform: translate(2px, 10px);
	transition: all 0.18s;
	pointer-events: none;
	color: var(--placeholderColor);
	align-self: center;
}


.btn-clear {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	right: 12px;
	fill: var(--neutral-grey-extradark);
	
	html[dir="rtl"] & {
		left: 12px;
		right: revert;
	}
}

.suffix-wrapper {
	
}

.short-description-wrapper {
	color: var(--neutral-grey-ultradark);
}


// variants
.TextField[data-variant="booking-widget"] {
	.inner-wrapper {
		/* Note: this is MIN query, not MAX */
		@media #{var.$query-min-md} {
			@apply pt-6 pb-3.5;
		}
	}
	.input{
		color: var(--primary-blue-base);
		font-weight: 600;
	}
}

.TextField[data-variant="without-label"] {
	.inner-wrapper {
		@apply pt-3.5 pb-3.5;
	}
	.text-placeholder {
		opacity: 1;
		transform: translate(1px, 0px);
	}
	.input {
		&:not(:placeholder-shown) ~ .text-placeholder {
			opacity: 0;
			transform: translate(2px, 10px);
		}
	}
}

.TextField[data-variant="input-hidden"] {
	.inner-container { display: none; }
	.short-description-wrapper { display: none; }
}

.TextField[data-variant="bst-widget"] {
	.inner-container {
		height: 64px;
	}
}

[data-use-theme="MHH"] {
	&.TextField[data-variant="booking-widget"] {
		.input {
			color: var(--primary-mhh-teal-base);
		}
	}
}

.TextField[data-use-theme="firefly"] {
	&[data-variant="booking-widget"] {
		.input {
			color: var(--secondary-firefly-orange-extradark);
			font-weight: 600;
		}
	}

	&.is-focused {
		.inner-container {
			--borderColor: var(--primary-firefly-orange-base);
		}
	}
	
	.required-asterisk {
		@apply text-secondary-firefly-orange-extradark;
	}
}
</style>
