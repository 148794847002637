<script>
export default {
	name: 'SubTabUpgrade',
};
</script>

<script setup>
import FormWrapper from '~/components/form/FormWrapper.vue';
import TextField from '~/components/form/TextField.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ToggleSwitch from '~/components/form/ToggleSwitch.vue';
import Tooltip from '~/components/Tooltip.vue';
import yup from 'mh-yup';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { getCookie } from '~/logic/helpers/cookies.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { waitFor } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { useEventBus } from '@vueuse/core';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	apiUrl: { type: String, default: '' },
	apiPointsUrl: { type: String, default: '' },
	bookingReference: { type: Object, default: null },
	lastName: { type: Object, default: null },
	description: { type: String, default: '' },
	tooltipLabel: { type: String, default: '' },
	tooltipBody: { type: String, default: '' },
	buttonLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	isToggle: { type: Boolean, default: false },
	toggleButtonLabel: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },

	isMainTab: { type: Boolean, default: false },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const bookingRefValue = ref('');
const lastNameValue = ref('');
const token = ref('');
const payByEnrichPoints = ref(false);

const { pageProperties } = useGlobalAEMState();
const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const { registerQueryChange } = useBookingWidgetUrlQuery(!props.isMainTab ? {
	mainTabName: 'manage-booking-tab',
	subTabName: 'upgrade',
} : {
	mainTabName: 'mh-upgrade-tab',
});

registerQueryChange(async (query) => {
	if (!query) return;
	bookingRefValue.value = query.bookingRef ?? '';
	lastNameValue.value = query.lastName ?? '';

	if (query.isPoints === 'true') {
		setEnrichPointStatus(true);
	}
});

const {
	isLoggedIn,
	isAuthStateReady,
} = useAuthStore();

const SAPPHIRE_COOKIE = 'sapphire';
token.value = getCookie(SAPPHIRE_COOKIE) ?? null;

watch( payByEnrichPoints, (newValue) => {
	// to avoid redirect to login page again. 
	if (!isLoggedIn.value && newValue) {
		handleRedirectToLogin();
	}
});

const handleRedirectToLogin = async () => {
	if (!props.togglePointsApiURL) {
		throw new Error('Failed to get endpoint: togglePointsApiURL');
	}

	try {
		const apiResponse = await axios.get(props.togglePointsApiURL, {
			params: {
				type: 'points',
				maintab: !props.isMainTab ? 'manage-booking-tab' : 'mh-upgrade-tab',
				subtab: !props.isMainTab ? 'upgrade' : null,
				bookingRef: bookingRefValue.value,
				lastName: lastNameValue.value,

				isPoints: true,
			},
		});
		const url = apiResponse.data;

		// set toggle switch to 'false', after login if url parameter has 'isPoints=true', set toggle set back to 'true' 
		payByEnrichPoints.value = false;
		
		window.location.href = url;
	} catch (e) {
		console.error('handleRedirectToLogin, ', 'Unable to fetch api', e);
	}
};

const handleSubmitValid = async ({ bookingRef, lastName }, actions) => {
	let apiResponse = null;
	// console.log('✅ handleSubmitValid values = ', values);
	
	// select cash - anxilla search
	if (props.apiUrl?.includes('anxilla') || props.apiUrl?.includes('ancillary_booking')) {
		const params = {
			'pnr': bookingRef,
			'lastname': lastName,
		};

		const url = new URL(props.apiUrl);

		Object.keys(params).forEach((key) => {
			url.searchParams.append(key, params[key]);
		});
		
		window.location.href = url.toString();

		return;
	}


	try {
		// select point - perform API call...
		const endpoint = payByEnrichPoints.value ? props.apiPointsUrl : props.apiUrl;
		
		apiResponse = await axios.get(endpoint, {
			params: {
				ticketNumber: bookingRef,
				lastName: lastName,
				paymentType: payByEnrichPoints.value ? 'miles' : 'cash',
				...( payByEnrichPoints.value && token.value && { token: token.value }),
				regionLanguage: country && language ? `${country}-${capitalizeString(language)}` : '',
			},
		});
		
		const { url, payload } = apiResponse.data;
		// 'RAW_DATA' doesn't required, delete it. 
		delete payload['RAW_DATA'];

		// 2nd api need to post with form submission
		// add extra property to payload object
		const generatedData = {};
		const entriesPayload = Object.entries(payload);
		for (let [index, [key, value]] of entriesPayload.entries()) {
			generatedData[index] = key;
		}
		const massageData = { ...generatedData, ...payload };
		const entriesMassageData = Object.entries(massageData);

		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', url);
		
		for (let [index, [key, value]] of entriesMassageData.entries()) {
			// console.log(`apiResponse => ${index}: ${key}: ${value}`);
			const _key = parseInt(key);
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}
		document.body.appendChild(form);
		form.submit();

	} catch (e) {
		console.error('handleSubmitValid, ', 'Unable to fetch api', e);
	}
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
	// perform side-effects, such as scrolling to the first error input
};

const bookingRefValidation = computed(() => {
	let yupSchema = yup.string().nullable().matches(/^[A-Za-z0-9]*$/, props.bookingReference?.alphanumericErrorMessage);
	const {
		isMandatory,
		errorMessage,
		maxLimit,
		maxLimitErrorMessage,
	} = props.bookingReference;
	
	if (isMandatory) yupSchema = yupSchema.required(errorMessage);
	
	if (maxLimit) yupSchema = yupSchema.max(maxLimit, maxLimitErrorMessage);

	return yupSchema;
});

const lastNameValidation = computed(() => {
	let yupSchema = yup.string().nullable();
	const {
		isMandatory,
		errorMessage,
	} = props.lastName;

	if (isMandatory) yupSchema = yupSchema.required(errorMessage);

	return yupSchema;
});

async function setEnrichPointStatus (boolean) {
	await nextTick();
	if (!boolean || !token.value) {
		payByEnrichPoints.value = false;
		return;
	}

	if (!isAuthStateReady.value) {
		const waitForAuthReady = waitFor(() => !!isAuthStateReady.value);
		await waitForAuthReady.start();
	}
	
	payByEnrichPoints.value = true;
}

const globalBookFlightBus = useEventBus('booking-widget:enrich-points-toggle-switch');
globalBookFlightBus.on(async (event, status) => {
	switch (event) {
		case 'update-toggle-switch':
			if (status === 'logout') {
				setEnrichPointStatus(false);
			}
			
			await nextTick();
			break;
		default:
			console.log(`Unknown event: ${event}. Ignore it.`);
			break;
	}
});


</script>

<template>
<div class="SubTabUpgrade">
	<FormWrapper
		v-slot="{ errors }"
		:class="{
			'-mt-16 lg:mt-0': props.isToggle,
		}"
		:focusFirstErrorElOnInvalidSubmit="true"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>
		<div v-if="props.isToggle" class="flex >lg:justify-end relative">
			<ToggleSwitch
				v-model="payByEnrichPoints"
				:rootAttrs="{
					class: 'mb-8',
				}"
				:ariaLabel="props.toggleButtonLabel"
				name="usingEnrichPoints"
			>
			</ToggleSwitch>
		</div>

		<div v-if="props.description" v-html-sanitize="props.description" class="mb-5"></div>

		<Tooltip v-if="props.tooltipLabel" class="inline-flex mb-5">
			<template #default>
				<div class="flex items-center">
					<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
					{{ props.tooltipLabel }}
				</div>
			</template>
			<template #mobile-title>
				{{ props.tooltipLabel }}
			</template>
			<template #tooltip-content>
				{{ props.tooltipBody }}
			</template>
		</Tooltip>

		<div class="grid-container mb-6">
			<div class="md:mb-3">
				<TextField
					v-if="props.bookingReference"
					v-model="bookingRefValue"
					variant="booking-widget"
					name="bookingRef"
					:ariaLabel="props.bookingReference?.label"
					:placeholder="props.bookingReference?.placeholderText"
					:validation="bookingRefValidation"
					:allowedKeys="/[A-Za-z0-9]/"
					max="6"
				>
					<template #short-description>
						<p v-if="props.bookingReference?.shortDescription && !errors?.bookingRef">{{ props.bookingReference?.shortDescription }}</p>
					</template>
				</TextField>
			</div>
			<div class="md:mb-6">
				<TextField
					v-if="props.lastName"
					v-model="lastNameValue"
					variant="booking-widget"
					name="lastName"
					:placeholder="props.lastName?.placeholderText"
					:ariaLabel="props.lastName?.label"
					:validation="lastNameValidation"
				/>
			</div>
			<div>
				<CTAButton
					v-if="props.buttonLabel"
					v-aa="[
						{
							clickName: props.buttonLabel,
							clickComponentType: 'Button',
							componentName: 'SubTabUpgrade',
							componentID: props.componentIdAA,
						},
						{
							name: props.buttonLabel,
							type: 'exit',
						},
					]"
					sizeType="medium"
					:disabled="lastNameValue && bookingRefValue && Object.keys(errors).length !== 0"
					ctaSizing="fluid"
					isSubmit
				>{{ props.buttonLabel }}</CTAButton>
			</div>
		</div>
	</FormWrapper>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabUpgrade {
	.grid-container {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr 1fr auto;
		gap: 1rem;

		@media #{var.$query-max-md} {
			display: block;
		}
	}

}

</style>
