<!--
	The name 'Footer' clashes with html <footer> component,
	hence we have to prefix it with 'App'.
-->
<script>
export default {
	name: 'AppFooter',
	editConfig: {
		emptyLabel: 'Footer',
		isEmpty (props) {
			return true;
		},
	},
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import AppFooterAccordion from '~/components/AppFooter/AppFooterAccordion.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useHeaderFooterStore } from '~/logic/header-footer/header-footer-store.js';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';

const { isEditorMode } = useGlobalAEMState();

const props = defineProps({
	generalTab: { type: Object, default: () => ({}) },
	exerciseYourRightsTab: { type: Object, default: () => ({}) },
	subsidiaryTab: { type: Object, default: () => ({}) },
	socialMediaTab: { type: Object, default: () => ({}) },
	tncTab: { type: Object, default: () => ({}) },
	copyrightTab: { type: Object, default: () => ({}) },
	componentIdAA: { type: String, default: '' },
});

const isPropsDataEmpty = () => {
	const exerciseYourRights = Object.keys(props.exerciseYourRightsTab).length === 0;
	const subsidiary = Object.keys(props.subsidiaryTab).length === 0;
	const socialMedia = Object.keys(props.socialMediaTab).length === 0;
	const tnc = Object.keys(props.tncTab).length === 0;
	const copyright = Object.keys(props.copyrightTab).length === 0;

	return exerciseYourRights && subsidiary && socialMedia && tnc && copyright;
};

const { footer: footerStore } = useHeaderFooterStore();

/* eslint-disable vue/no-setup-props-destructure */
// it's okay, reactivity on these props are not needed anyway
footerStore.subsidiaryTab = props.subsidiaryTab;
footerStore.tncTab = props.tncTab;
footerStore.copyrightTab = props.copyrightTab;
/* eslint-enable */


</script>

<template>
<template v-if="isPropsDataEmpty()">
	<p v-if="isEditorMode" class="text-center">🔧 Please click here to configure footer</p>
</template>
<template v-else>
	<footer
		v-if="(isMobileViewport && !props.generalTab.isHideInMobile) || (!isMobileViewport && !props.generalTab.isHideInDesktop)"
		class="AppFooter md:hidden"
	>
		<div
			class="footer-inner-container bg-secondary-blue-extradark"
			:class="{ '>md:rounded-t-7xl': props.generalTab.isCurvedCorners }"
		>
			<div class="generic-container gap-x-4 py-4 >md:pt-16 >md:pb-12 text-white">
				<div class="footer-top">
					<AppFooterAccordion
						:items="props.generalTab.navigationMenu"
					/>
				</div>
				<div class="footer-bottom">
					<div class=">lg:w-5/12 flex items-start">
						<div class="bottom-left-col">
							<div class="rights-logo-wrapper flex gap-x-6">
								<!-- <img
									v-for="item in props.exerciseYourRights"
									:key="item.imagePath"
									:src="item.imagePath"
									class="rights-logo h-[120px] w-auto inline-block"
									:alt="item.imageAltText"
								/> -->
								<AppHyperlink
									v-if="props.exerciseYourRightsTab.image1.path"
									v-aa="[
										{
											clickName: props.exerciseYourRightsTab.image1.altText,
											clickComponentType: 'URL',
											componentName: 'AppFooter',
											componentID: props.componentIdAA,
										},
										{
											name: props.exerciseYourRightsTab.image1.altText,
											type: isUrlMatchCurrentHostname(props.exerciseYourRightsTab.image1.url) ? 'other' : 'exit',
										},
									]"
									:href="props.exerciseYourRightsTab.image1.url"
									:target="props.exerciseYourRightsTab.image1.isOpenNewTab ? '_blank' : '_self'"
									rel="noopener noreferrer"
									class="rights-logo-anchor"
									:showExternalLinkIcon="false"
								>
									<img
										:src="props.exerciseYourRightsTab.image1.path"
										class="rights-logo h-20 w-auto inline-block"
										loading="lazy"
										:alt="props.exerciseYourRightsTab.image1.altText"
									/>
								</AppHyperlink>
								<AppHyperlink
									v-if="props.exerciseYourRightsTab.image2.path"
									v-aa="[
										{
											clickName: props.exerciseYourRightsTab.image2.altText,
											clickComponentType: 'URL',
											componentName: 'AppFooter',
											componentID: props.componentIdAA,
										},
										{
											name: props.exerciseYourRightsTab.image2.altText,
											type: isUrlMatchCurrentHostname(props.exerciseYourRightsTab.image2.url) ? 'other' : 'exit',
										},
									]"
									:href="props.exerciseYourRightsTab.image2.url"
									:target="props.exerciseYourRightsTab.image2.isOpenNewTab ? '_blank' : '_self'"
									rel="noopener noreferrer"
									class="rights-logo-anchor"
									:showExternalLinkIcon="false"
								>
									<img
										:src="props.exerciseYourRightsTab.image2.path"
										class="rights-logo h-20 w-auto inline-block"
										loading="lazy"
										:alt="props.exerciseYourRightsTab.image2.altText"
									/>
								</AppHyperlink>
							</div>
							<div class="partner-logo-wrapper">
								<AppHyperlink
									v-for="item in props.subsidiaryTab.subsidiaryList"
									:key="item.imageAltText"
									v-aa="[
										{
											clickName: item.imageAltText,
											clickComponentType: 'URL',
											componentName: 'AppFooter',
											componentID: props.componentIdAA,
										},
										{
											name: item.imageAltText,
											type: isUrlMatchCurrentHostname(item.imageUrl) ? 'other' : 'exit',
										},
									]"
									:href="item.imageUrl"
									:target="item.isOpenNewTab ? '_blank' : '_self'"
									class="partner-logo inline-block"
									:class="{ 'hidden': (isMobileViewport && item.isHideInMobile) || (!isMobileViewport && item.isHideInDesktop) }"
									:showExternalLinkIcon="false"
								>
									<img
										:src="item.image"
										class="h-[40px] w-auto inline-block"
										loading="lazy"
										:alt="item.imageAltText"
									/>
								</AppHyperlink>
							</div>
							<p v-html-sanitize="props.copyrightTab.text" class="copyright-text text-neutral-grey-extradark text-xs font-bold >lg:hidden >md:w-11/12 lg:w-full mx-auto mt-8"></p>
						</div>
					</div>
					<div class=">lg:w-7/12 >lg:flex flex-col items-end justify-end">
						<div class="bottom-right-col">
							<div class="social-icons-wrapper">
								<p class="text-neutral-grey-extradark text-xs font-bold mb-4 lg:mt-10">{{ props.socialMediaTab.label }}</p>
								<ul class="social-icons-list >lg:text-right >lg:flex >lg:items-center">
									<li
										v-for="socialItem in props.socialMediaTab.socialMediaList"
										:key="socialItem.iconImage"
										class="social-icons-list-item inline-block w-8 h-8"
									>
										<AppHyperlink
											v-aa="[
												{
													clickName: socialItem.iconAltText,
													clickComponentType: 'URL',
													componentName: 'AppFooter',
													componentID: props.componentIdAA,
												},
												{
													name: socialItem.iconAltText,
													type: isUrlMatchCurrentHostname(socialItem.iconUrl) ? 'other' : 'exit',
												},
											]"
											class="social-icons-anchor"
											:class="{
												'align-middle': socialItem.iconType !== 'icon-font-awesome-id',
											}"
											:href="socialItem.iconUrl"
											:target="socialItem.isOpenNewTab ? '_blank' : '_self'"
											rel="noopener noreferrer"
											:showExternalLinkIcon="false"
										>

											<FontAwesomeLazy
												v-if="socialItem.iconType === 'icon-font-awesome-id'"
												class="social-icon-logo fill-neutral-white-base hover:fill-primary-blue-extralight mx-auto"
												:iconId="socialItem.iconId"
												role="presentation"
											/>

											<img
												v-else
												:src="socialItem.iconImage"
												class="social-icons-image w-8 h-8 hover:opacity-50"
												loading="lazy"
												:alt="socialItem.iconAltText"
												role="presentation"
											/>
											<span class="sr-only">{{ socialItem.iconAltText }}</span>
										</AppHyperlink>
									</li>
								</ul>
							</div>
							<div class="copyright-wrapper">
								<div class="copyright-list">
									<ul class="mb-4 md:mb-10 lg:flex lg:flex-col">
										<li v-for="(item, index) in props.tncTab.tncList" :key="`${item.title}-${index}`" class="copyright-list inline-block">
											<AppHyperlink
												v-aa="[
													{
														clickName: item.title,
														clickComponentType: 'URL',
														componentName: 'AppFooter',
														componentID: props.componentIdAA,
													},
													{
														name: item.title,
														type: isUrlMatchCurrentHostname(item.titleUrl) ? 'other' : 'exit',
													},
												]"
												class="copyright-link text-neutral-white-base hover:text-primary-blue-extralight"
												:href="item.titleUrl"
												:target="item.isOpenNewTab ? '_blank' : '_self'"
												rel="noopener noreferrer"
												:showExternalLinkIcon="false"
											>
												<span class="copyright-link-text">{{ item.title }}</span>
											</AppHyperlink>
										</li>
									</ul>
									<p v-html-sanitize="props.copyrightTab.text" class="copyright-text text-neutral-grey-extradark text-xs font-bold lg:hidden"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>

	<div v-if="isEditorMode && props.generalTab.isHideInDesktop" class="generic-container py-10 text-center">
		<p>Hidden in: <span v-if="props.generalTab.isHideInDesktop">Desktop</span> <span v-if="props.generalTab.isHideInMobile">, Mobile</span></p>
	</div>
</template>
</template>


<style lang="scss">
.global-footer {
	margin-top: auto;
}

/*
* Styling media social icons
* for FontAwesomeLazy
*/
.social-icons-anchor {
	svg {
		height: 32px;
		width: 32px;
	}
}

html.showing-subpages {
	.AppFooter {
		display: none;
	}
}
</style>


<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

.AppFooter {
	width: 100%;

	.footer-bottom {
		display: flex;
		flex-direction: column-reverse;

		@media #{var.$query-min-lg} {
			@apply mt-8;
			flex-direction: row;
		}

		.bottom-left-col {
			margin-top: 18px;

			.partner-logo-wrapper {
				display: flex;
				column-gap: 26px;
				flex-wrap: wrap;

				.AppHyperlink {
					width: 90px;
				}
				
				@media #{var.$query-min-xl} {
					column-gap: 26px;
				}
			}

			.rights-logo-wrapper {
				img {
					&.rights-logo {
						&.inline-block {
							max-width: 150px;
						}
					}
				}
			}
		}


		.bottom-right-col {
			flex-direction: column;
			align-items: flex-end;
			justify-content: end;

			@media #{var.$query-min-md} {
				margin-bottom: 0px;
			}

			@media #{var.$query-min-lg} {
				display: flex;
				margin-bottom: 74px;
			}

			.social-icons-list {
				display: flex;
				column-gap: 32px;

				@media #{var.$query-max-md} {
					display: grid;
					grid-template-columns: repeat(5, 1fr);
					grid-column-gap: 40px;
					grid-row-gap: 24px;
				}
			}

			.social-icons-wrapper {
				margin-bottom: 42px;

				@media #{var.$query-max-md} {
					@apply mb-10;
					width: 100%;
				}

				.social-icons-list-item {

					// &:not(:last-child) {
					// 	@media #{var.$query-min-md} {
					// 		@apply mr-8;
					// 	}
					// }

					.social-icons-image {
						display: inline-block;
					}
				}
			}

			.copyright-list {
				@apply font-semibold;

				&:not(:last-child) {
					@apply mb-5;

					@media #{var.$query-min-lg} {
						@apply mr-5;
					}
				}
			}
		}
	}

	.copyright-text {
		@apply break-words text-right;

		@media #{var.$query-max-md} {
			font-size: 10px;
			@apply mt-8 text-center;
		}
		
		html[dir="rtl"] & {
			@apply text-left;
		}
	}

	.copyright-link-text {
		font-size: 14px;
	}
}
</style>
